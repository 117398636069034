import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class NotificationService {
    #crudService = new CrudService("/sendNotification", "/dashboard", "/", apiEndpoints.sendNotifications);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;

    // getAllMobileTranslationItem = (typeId) => this.#crudService.getAllByQuery(`?type=${typeId}`);

    // getSingleMenuItem = (menuItemId) => this.#crudService.getSingle(menuItemId);

    addMenuItem = (notificationData) => this.#crudService.add(notificationData);
    addNotification = (notificationData) => this.#crudService.add(notificationData);

    // deleteMenuItem = (menuItemId) => this.#crudService.delete(menuItemId);
}

export const notificationService = new NotificationService();
