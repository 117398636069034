import axios from "axios";
import jwtDecode from "jwt-decode";
import { httpErrors } from "../api";

// Core Service
// Service to handle anything related to an api, request, getting api data for instance access token, storing token, remove token.
class ApiService {
    baseURL = process.env.REACT_APP_API_ENDPOINT;
    token = process.env.REACT_APP_API_TOKEN;

    get accessToken() {
        return window.localStorage.getItem("accessToken") ?? "";
    }

    get language() {
        return window.localStorage.getItem("language") ?? 1;
    }

    get getAccessTokenDecoded() {
        return window.localStorage.getItem("accessToken") ? jwtDecode(window.localStorage.getItem("accessToken")) : null;
    }

    // Get an authenticated api call with access token
    authenticated() {
        axios.interceptors.response.use(null, httpErrors);

        axios.defaults.baseURL = this.baseURL;

        Object.assign(axios.defaults.headers.common, { Token: this.token, Language: this.language, Authorization: `Bearer ${this.accessToken}` });

        return axios;

        // return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Language: this.language, Authorization: `Bearer ${ this.accessToken }` } });
    }

    // Get an unauthenticated api call with access token
    unauthenticated() {
        return axios.create({ baseURL: this.baseURL, headers: { Token: this.token, Language: this.language } });
    }

    // Store access token
    storeAccessToken(token) {
        window.localStorage.setItem("accessToken", token);
    }

    // Remove access token
    removeAccessToken() {
        window.localStorage.removeItem("accessToken");
    }
}

export const apiService = new ApiService();
