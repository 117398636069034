import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class GetSingleStoreCloseOpen {
    #crudService = new CrudService("/storeCloseOpen", "", "", apiEndpoints.storeCloseOpen);
    mainRoute = this.#crudService.mainRoute;

    getSingleStoreCloseOpen = (storeId) => this.#crudService.getAllByQuery(`?store_id=${storeId}`);
}

class StoreWorkingHoursService {
    #crudService = new CrudService("/storeWorkingHours", "/addStoreWorkingHour", "/editStoreWorkingHour", apiEndpoints.storeWorkingHours);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getSingleStoreCloseOpen = new GetSingleStoreCloseOpen();

    getAllStoreWorkingHours = (storeId) => this.#crudService.getAllByQuery(`?store_id=${storeId}`);

    getSingleStoreWorkingHour = (storeWorkingHourId) => this.#crudService.getSingle(storeWorkingHourId);

    addStoreWorkingHour = (newStoreWorkingHour) => this.#crudService.add(newStoreWorkingHour);

    updateStoreWorkingHour = (storeWorkingHourId, updatedStoreWorkingHour) => this.#crudService.update(storeWorkingHourId, updatedStoreWorkingHour);

    deleteStoreWorkingHour = (storeWorkingHourId) => this.#crudService.delete(storeWorkingHourId);
}

export const storeWorkingHoursService = new StoreWorkingHoursService();
