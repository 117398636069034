import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class TrackDriversService {
    #crudService = new CrudService('/trackDrivers', '', '', apiEndpoints.getDriverForTracking);
    mainRoute = this.#crudService.mainRoute;

    getAllDriversLocation = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);
}

export const trackDriversService = new TrackDriversService();
