import React from "react";
import { CLink } from "@coreui/react";
import { splitCamelCase } from "./misc";

export const getTableActions = (linksActions = [], deleteLanguage) => (
    <td className="table__actions d-flex flex-column justify-content-start">
        {linksActions &&
            linksActions.map(({ linkTo, actionText }) => (
                <CLink key={actionText} to={linkTo} className="font-weight-bold text-capitalize">
                    {splitCamelCase(actionText)}
                </CLink>
            ))}

        {deleteLanguage && (
            <button aria-label="delete language" className="text-left text-danger font-weight-bold" onClick={deleteLanguage}>
                Delete
            </button>
        )}
    </td>
);

export const getSelectedLanguagesTable = (table = []) => table.map(({ language, value: languageValue, ...rest }) => getSelectedLanguagesTD({ languageId: language.id, languageType: language.name, languageValue, ...rest }));

export const getSelectedLanguagesTD = ({ removeAction, ...rest }) => ({
    ...rest,
    action: (
        <td>
            <button className="text-danger font-weight-bold" onClick={() => removeAction(rest.languageId)}>
                Delete
            </button>
        </td>
    ),
});
