import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class AppIntroService {
    #crudService = new CrudService('/appIntro', '/addAppIntro', '/editAppIntro', apiEndpoints.appIntro);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;

    getAllAppIntros = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    addAppIntro = (newCountryCategory) => this.#crudService.add(newCountryCategory);

    deleteAppIntro = (countryCategoryId) => this.#crudService.delete(countryCategoryId);
}

export const appIntroService = new AppIntroService();
