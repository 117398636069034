import { useSelector } from "react-redux";
import { adminService } from "src/services/AdminsService";
import { mobileTranslationService } from "src/services/MobileTranslationService";
import { notificationService } from "src/services/NotificationsService";
import { campaignsService, categoryService, countryService, dayService, languageService, ordersService, popupPointsService, storesService, usersService } from "../services";

const adminType = localStorage.getItem("adminType");

const adminNav =
    +adminType === 99
        ? [
              { _tag: "CSidebarNavItem", name: "Dashboard", to: "/dashboard", icon: "cil-speedometer" },
              { _tag: "CSidebarNavItem", name: "Countries", to: countryService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Languages", to: languageService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Categories", to: categoryService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Days", to: dayService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Orders", to: ordersService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Popup Points", to: popupPointsService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Stores", to: storesService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Customers", to: usersService.customers.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Drivers", to: usersService.drivers.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Campaigns", to: campaignsService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Mobile Translation", to: mobileTranslationService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Send Notifications", to: notificationService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Admins", to: adminService.mainRoute, icon: "cil-globe-alt" },
          ]
        : [
              { _tag: "CSidebarNavItem", name: "Countries", to: countryService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Orders", to: ordersService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Stores", to: storesService.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Customers", to: usersService.customers.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Drivers", to: usersService.drivers.mainRoute, icon: "cil-globe-alt" },
              { _tag: "CSidebarNavItem", name: "Campaigns", to: campaignsService.mainRoute, icon: "cil-globe-alt" },
          ];

export default adminNav;
