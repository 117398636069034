import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class CategoryService {
    #crudService = new CrudService('/categories', '/addCategory', '/editCategory', apiEndpoints.categories);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllCategories = () => this.#crudService.getAll();

    getNotAddedCountryCategories = async (countryId) => {
        this.#crudService.baseEndpoint = apiEndpoints.getNotAddedCountryCategories;

        const notAddedCategories = await this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

        this.#crudService.baseEndpoint = apiEndpoints.categories;

        return notAddedCategories;
    };

    getSingleCategory = (categoryId) => this.#crudService.getSingle(categoryId);

    addCategory = (newCategory) => this.#crudService.add(newCategory);

    updateCategory = (categoryId, updatedCategory) => this.#crudService.update(categoryId, updatedCategory);

    deleteCategory = (categoryId) => this.#crudService.delete(categoryId);
}

export const categoryService = new CategoryService();
