import React, { lazy } from "react";
import {
    appIntroService,
    areaService,
    campaignsService,
    categoryService,
    cityService,
    countryCategoryService,
    countryService,
    dayService,
    driverAttachmentsService,
    driverRequirementService,
    driversPaymentsService,
    homeAdsService,
    languageService,
    menuCategoriesService,
    menuItemAddonsGroupsService,
    menuItemsService,
    ordersService,
    popupPointsService,
    publicPagesService,
    storeCampaignsService,
    storeImagesService,
    storesService,
    storeUserService,
    storeWorkingHoursService,
    trackDriversService,
    usersService,
} from "./services";
import { adminService } from "./services/AdminsService";
import { mobileTranslationService } from "./services/MobileTranslationService";
import { notificationService } from "./services/NotificationsService";

const Toaster = React.lazy(() => import("./views/notifications/toaster/Toaster"));
const Tables = React.lazy(() => import("./views/base/tables/Tables"));

const Breadcrumbs = React.lazy(() => import("./views/base/breadcrumbs/Breadcrumbs"));
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const BasicForms = React.lazy(() => import("./views/base/forms/BasicForms"));

const Jumbotrons = React.lazy(() => import("./views/base/jumbotrons/Jumbotrons"));
const ListGroups = React.lazy(() => import("./views/base/list-groups/ListGroups"));
const Navbar = React.lazy(() => import("./views/base/navbar/Navbar"));
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const ProgressBar = React.lazy(() => import("./views/base/progress-bar/ProgressBar"));
const Switches = React.lazy(() => import("./views/base/switches/Switches"));

const Tabs = React.lazy(() => import("./views/base/tabs/Tabs"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));
const BrandButtons = React.lazy(() => import("./views/buttons/brand-buttons/BrandButtons"));
const ButtonDropdowns = React.lazy(() => import("./views/buttons/button-dropdowns/ButtonDropdowns"));
const ButtonGroups = React.lazy(() => import("./views/buttons/button-groups/ButtonGroups"));
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const Charts = React.lazy(() => import("./views/charts/Charts"));

const CoreUIIcons = React.lazy(() => import("./views/icons/coreui-icons/CoreUIIcons"));
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));

const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() => import("./views/theme/typography/Typography"));

const routes = [
    { path: "/", exact: true, name: "Home" },
    { path: "/dashboard", name: "Dashboard", component: lazy(() => import("./views/dashboard/Dashboard")) },

    // Template Standards
    { path: "/theme", name: "Theme", component: Colors, exact: true },
    { path: "/theme/colors", name: "Colors", component: Colors },
    { path: "/theme/typography", name: "Typography", component: Typography },
    { path: "/base", name: "Base", component: Cards, exact: true },
    { path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs },
    { path: "/base/cards", name: "Cards", component: Cards },
    { path: "/base/carousels", name: "Carousel", component: Carousels },
    { path: "/base/collapses", name: "Collapse", component: Collapses },
    { path: "/base/forms", name: "Forms", component: BasicForms },
    { path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
    { path: "/base/list-groups", name: "List Groups", component: ListGroups },
    { path: "/base/navbar", name: "Navbar", component: Navbar },
    { path: "/base/navs", name: "Navs", component: Navs },
    { path: "/base/popovers", name: "Popovers", component: Popovers },
    { path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar },
    { path: "/base/switches", name: "Switches", component: Switches },
    { path: "/base/tables", name: "Tables", component: Tables },
    { path: "/base/tabs", name: "Tabs", component: Tabs },
    { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
    { path: "/buttons", name: "Buttons", component: Buttons, exact: true },
    { path: "/buttons/buttons", name: "Buttons", component: Buttons },
    { path: "/buttons/button-dropdowns", name: "Dropdowns", component: ButtonDropdowns },
    { path: "/buttons/button-groups", name: "Button Groups", component: ButtonGroups },
    { path: "/buttons/brand-buttons", name: "Brand Buttons", component: BrandButtons },
    { path: "/charts", name: "Charts", component: Charts },
    { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
    { path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons },
    { path: "/icons/flags", name: "Flags", component: Flags },
    { path: "/icons/brands", name: "Brands", component: Brands },
    { path: "/notifications", name: "Notifications", component: Alerts, exact: true },
    { path: "/notifications/alerts", name: "Alerts", component: Alerts },
    { path: "/notifications/modals", name: "Modals", component: Modals },
    { path: "/notifications/toaster", name: "Toaster", component: Toaster },

    // Countries Start
    { path: countryService.mainRoute, exact: true, name: "Countries", component: lazy(() => import("./views/pages/countries/Countries")) },
    { path: countryService.addRoute, exact: true, name: "Add Country", component: lazy(() => import("./views/pages/countries/AddCountry")) },
    { path: `${countryService.editRoute}/:id`, exact: true, name: "Edit Country", component: lazy(() => import("./views/pages/countries/EditCountry")) },
    // Countries End

    // Cities Start
    { path: `${cityService.mainRoute}/:countryId`, exact: true, name: "Cities", component: lazy(() => import("./views/pages/cities/Cities")) },
    { path: `${cityService.addRoute}/:countryId`, exact: true, name: "Add City", component: lazy(() => import("./views/pages/cities/AddCity")) },
    { path: `${cityService.editRoute}/:id/:countryId`, exact: true, name: "Edit City", component: lazy(() => import("./views/pages/cities/EditCity")) },
    // Cities End

    // Areas Start
    { path: `${areaService.mainRoute}/:cityId`, exact: true, name: "Areas", component: lazy(() => import("./views/pages/areas/Areas")) },
    { path: `${areaService.addRoute}/:cityId`, exact: true, name: "Add Area", component: lazy(() => import("./views/pages/areas/AddArea")) },
    { path: `${areaService.editRoute}/:id/:cityId`, exact: true, name: "Edit Area", component: lazy(() => import("./views/pages/areas/EditArea")) },
    // Areas End

    // Country Categories Start
    { path: `${countryCategoryService.mainRoute}/:countryId`, exact: true, name: "Country Categories", component: lazy(() => import("./views/pages/country-categories/CountryCategories")) },
    { path: `${countryCategoryService.addRoute}/:countryId`, exact: true, name: "Add Country Category", component: lazy(() => import("./views/pages/country-categories/AddCountryCategory")) },
    // Country Categories End

    // App Intros Start
    { path: `${appIntroService.mainRoute}/:countryId`, exact: true, name: "App Intros", component: lazy(() => import("./views/pages/app-intros/AppIntros")) },
    { path: `${appIntroService.addRoute}/:countryId`, exact: true, name: "Add App Intro", component: lazy(() => import("./views/pages/app-intros/AddAppIntro")) },
    // App Intros End

    // Driver Requirements Start
    { path: `${driverRequirementService.mainRoute}/:countryId`, exact: true, name: "Driver Requirements", component: lazy(() => import("./views/pages/country-driver-requriements/DriverRequirements")) },
    { path: `${driverRequirementService.addRoute}/:countryId`, exact: true, name: "Add Driver Requirement", component: lazy(() => import("./views/pages/country-driver-requriements/AddDriverRequirement")) },
    { path: `${driverRequirementService.editRoute}/:id/:countryId`, exact: true, name: "Edit Driver Requirement", component: lazy(() => import("./views/pages/country-driver-requriements/EditDriverRequirement")) },
    // Driver Requirements End

    // Home Ads Start
    { path: `${homeAdsService.mainRoute}/:countryId`, exact: true, name: "Home Ads", component: lazy(() => import("./views/pages/country-home-ads/HomeAds")) },
    { path: `${homeAdsService.addRoute}/:countryId`, exact: true, name: "Add Home Ad", component: lazy(() => import("./views/pages/country-home-ads/AddHomeAd")) },
    { path: `${homeAdsService.editRoute}/:id/:countryId`, exact: true, name: "Edit Home Ad", component: lazy(() => import("./views/pages/country-home-ads/EditHomeAd")) },
    // Home Ads End

    // Home Ads Start
    { path: `${publicPagesService.mainRoute}/:countryId`, exact: true, name: "Public Pages", component: lazy(() => import("./views/pages/country-public-pages/PublicPages")) },
    { path: `${publicPagesService.addRoute}/:countryId`, exact: true, name: "Add Public Page", component: lazy(() => import("./views/pages/country-public-pages/AddPublicPage")) },
    { path: `${publicPagesService.editRoute}/:id/:countryId`, exact: true, name: "Edit Public Page", component: lazy(() => import("./views/pages/country-public-pages/EditPublicPage")) },
    // Home Ads End

    // Driver Payments Start
    { path: `${driversPaymentsService.mainRoute}/:countryId`, exact: true, name: "Drivers Statement of account", component: lazy(() => import("./views/pages/country-driver-payments/DriversAccountStatement")) },
    { path: `${driversPaymentsService.driverTransactionsRoute}/:id`, exact: true, name: "Driver Transactions", component: lazy(() => import("./views/pages/country-driver-payments/DriverTransactions")) },
    { path: `${driversPaymentsService.driverPaymentsRoute}/:id`, exact: true, name: "Driver Payments", component: lazy(() => import("./views/pages/country-driver-payments/DriverPayments")) },
    { path: `${driversPaymentsService.addRoute}/:userId`, exact: true, name: "Add Driver Payment", component: lazy(() => import("./views/pages/country-driver-payments/AddDriverPayment")) },
    // Driver Payments End

    // Languages Start
    { path: languageService.mainRoute, exact: true, name: "Languages", component: lazy(() => import("./views/pages/languages/Languages")) },
    { path: languageService.addRoute, exact: true, name: "Add Language", component: lazy(() => import("./views/pages/languages/AddLanguage")) },
    { path: `${languageService.editRoute}/:id`, exact: true, name: "Edit Language", component: lazy(() => import("./views/pages/languages/EditLanguage")) },
    // Languages End

    // Categories Start
    { path: categoryService.mainRoute, exact: true, name: "Categories", component: lazy(() => import("./views/pages/categories/Categories")) },
    { path: categoryService.addRoute, exact: true, name: "Add Category", component: lazy(() => import("./views/pages/categories/AddCategory")) },
    { path: `${categoryService.editRoute}/:id`, exact: true, name: "Edit Category", component: lazy(() => import("./views/pages/categories/EditCategory")) },
    // Categories End

    // Days Start
    { path: dayService.mainRoute, exact: true, name: "Days", component: lazy(() => import("./views/pages/days/Days")) },
    { path: dayService.addRoute, exact: true, name: "Add Day", component: lazy(() => import("./views/pages/days/AddDay")) },
    { path: `${dayService.editRoute}/:id`, exact: true, name: "Edit Day", component: lazy(() => import("./views/pages/days/EditDay")) },
    // Days End

    // Orders Start
    { path: ordersService.mainRoute, exact: true, name: "Orders", component: lazy(() => import("./views/pages/orders/Orders")) },
    { path: `${ordersService.mainRoute}/:orderId`, exact: true, name: "Single Order Invoice", component: lazy(() => import("./views/pages/orders/OrderInvoice")) },
    { path: `${ordersService.orderComplaints.mainRoute}/:orderId`, exact: true, name: "Order Complaints", component: lazy(() => import("./views/pages/orders/OrderComplaints")) },
    { path: `${ordersService.orderOffers.mainRoute}/:orderId`, exact: true, name: "Order Offers", component: lazy(() => import("./views/pages/orders/OrderOffers")) },
    { path: `${ordersService.orderMessages.mainRoute}/:orderId`, exact: true, name: "Order Messages", component: lazy(() => import("./views/pages/orders/OrderMessages")) },
    { path: `${ordersService.orderReviews.mainRoute}/:orderId`, exact: true, name: "Order Reviews", component: lazy(() => import("./views/pages/orders/OrderReviews")) },
    { path: `${ordersService.orderDriverNotifications.mainRoute}/:orderId`, exact: true, name: "Order Driver Notifications", component: lazy(() => import("./views/pages/orders/OrderDriverNotifications")) },
    // Orders End

    // Popup Points Start
    { path: popupPointsService.mainRoute, exact: true, name: "Popup Points", component: lazy(() => import("./views/pages/popup-points/PopupPoints")) },
    { path: popupPointsService.addRoute, exact: true, name: "Add Popup Point", component: lazy(() => import("./views/pages/popup-points/AddPopupPoint")) },
    { path: `${popupPointsService.editRoute}/:id`, exact: true, name: "Edit Popup Point", component: lazy(() => import("./views/pages/popup-points/EditPopupPoint")) },
    // Popup Points End

    // Stores Start
    { path: storesService.mainRoute, exact: true, name: "Stores", component: lazy(() => import("./views/pages/stores/Stores")) },
    { path: storesService.addRoute, exact: true, name: "Add Store", component: lazy(() => import("./views/pages/stores/AddStore")) },
    { path: `${storesService.editRoute}/:id`, exact: true, name: "Edit Store", component: lazy(() => import("./views/pages/stores/EditStore")) },
    // Stores End

    // Store Branches Start
    { path: `${storesService.branches.mainRoute}/:storeId`, exact: true, name: "Store Branches", component: lazy(() => import("./views/pages/store-branches/StoreBranches")) },
    { path: `${storesService.branches.addRoute}/:storeId`, exact: true, name: "Add Store Branch", component: lazy(() => import("./views/pages/store-branches/AddStoreBranch")) },
    { path: `${storesService.branches.editRoute}/:id/:storeId`, exact: true, name: "Edit Store Branch", component: lazy(() => import("./views/pages/store-branches/EditStoreBranch")) },
    // Store Branches End

    // Menu Categories Start
    { path: `${menuCategoriesService.mainRoute}/:storeId`, exact: true, name: "Menu Categories", component: lazy(() => import("./views/pages/store-menu-categories/MenuCategories")) },
    { path: `${menuCategoriesService.addRoute}/:storeId`, exact: true, name: "Add Menu Category", component: lazy(() => import("./views/pages/store-menu-categories/AddMenuCategory")) },
    { path: `${menuCategoriesService.editRoute}/:id/:storeId`, exact: true, name: "Edit Menu Category", component: lazy(() => import("./views/pages/store-menu-categories/EditMenuCategory")) },
    // Menu Categories End

    // Menu Items Start
    { path: `${menuItemsService.mainRoute}/:storeMenuCategoryId`, exact: true, name: "Menu Items", component: lazy(() => import("./views/pages/store-menu-items/MenuItems")) },
    { path: `${menuItemsService.addRoute}/:storeMenuCategoryId`, exact: true, name: "Add Menu Item", component: lazy(() => import("./views/pages/store-menu-items/AddMenuItem")) },
    { path: `${menuItemsService.editRoute}/:id/:storeMenuCategoryId`, exact: true, name: "Edit Menu Item", component: lazy(() => import("./views/pages/store-menu-items/EditMenuItem")) },
    // Menu Items End

    // Menu Items Addons Groups Start
    { path: `${menuItemAddonsGroupsService.mainRoute}/:storeMenuItemId`, exact: true, name: "Menu Items Addons Groups", component: lazy(() => import("./views/pages/store-menu-items-addons-groups/MenuItemsAddonsGroups")) },
    { path: `${menuItemAddonsGroupsService.addRoute}/:storeMenuItemId`, exact: true, name: "Add Menu Item Addon Group", component: lazy(() => import("./views/pages/store-menu-items-addons-groups/AddMenuItemAddonGroup")) },
    { path: `${menuItemAddonsGroupsService.editRoute}/:id/:storeMenuItemId`, exact: true, name: "Edit Menu Item Addon Group", component: lazy(() => import("./views/pages/store-menu-items-addons-groups/EditMenuItemAddonGroup")) },
    // Menu Items Addons Groups End

    // Store Images Start
    { path: `${storeImagesService.mainRoute}/:storeId`, exact: true, name: "Store Images", component: lazy(() => import("./views/pages/store-images/StoreImages")) },
    { path: `${storeImagesService.addRoute}/:storeId`, exact: true, name: "Add Store Image", component: lazy(() => import("./views/pages/store-images/AddStoreImage")) },
    // Store Images End

    // Store Campaigns Start
    { path: `${storeCampaignsService.mainRoute}/:storeId`, exact: true, name: "Store Campaigns", component: lazy(() => import("./views/pages/store-campaigns/StoreCampaigns")) },
    { path: `${storeCampaignsService.addRoute}/:storeId`, exact: true, name: "Add Store Campaign", component: lazy(() => import("./views/pages/store-campaigns/AddStoreCampaign")) },
    { path: `${storeCampaignsService.editRoute}/:id/:storeId`, exact: true, name: "Edit Store Campaign", component: lazy(() => import("./views/pages/store-campaigns/EditStoreCampaign")) },
    // Store Campaigns End

    // Store Working Hours Start
    { path: `${storeWorkingHoursService.mainRoute}/:storeId`, exact: true, name: "Store Working Hours", component: lazy(() => import("./views/pages/store-working-hours/StoreWorkingHours")) },
    { path: `${storeWorkingHoursService.addRoute}/:storeId`, exact: true, name: "Add Store Working Hour", component: lazy(() => import("./views/pages/store-working-hours/AddStoreWorkingHour")) },
    { path: `${storeWorkingHoursService.editRoute}/:id/:storeId`, exact: true, name: "Edit Store Working Hour", component: lazy(() => import("./views/pages/store-working-hours/EditStoreWorkingHour")) },
    // Store Working Hours End

    // Store open and close time Start

    { path: `${storeWorkingHoursService.getSingleStoreCloseOpen.mainRoute}/:id`, exact: true, name: "Store Logged", component: lazy(() => import("./views/pages/StoreCloseOpen/StoreCloseOpen")) },

    // Store open and close time end

    // Store User Start
    { path: `${usersService.store.mainRoute}/:storeId`, exact: true, name: "Store User", component: lazy(() => import("./views/pages/store-user/StoreUser")) },
    { path: `${usersService.store.addRoute}/:storeId`, exact: true, name: "Add Store User", component: lazy(() => import("./views/pages/store-user/AddStoreUser")) },
    { path: `${usersService.store.editRoute}/:id/:storeId`, exact: true, name: "Edit Store User", component: lazy(() => import("./views/pages/store-user/EditStoreUser")) },
    // Store User End

    // Store User Start
    { path: `${storeUserService.mainRoute}/:id/:storeId`, exact: true, name: "Store User Change Password", component: lazy(() => import("./views/pages/store-user/StoreUserChangePassword")) },
    // Store User Start

    // Customers Start
    { path: usersService.customers.mainRoute, exact: true, name: "Customers", component: lazy(() => import("./views/pages/customers/Customers")) },
    { path: usersService.customers.addRoute, exact: true, name: "Add Customer", component: lazy(() => import("./views/pages/customers/AddCustomer")) },
    { path: `${usersService.customers.editRoute}/:id`, exact: true, name: "Edit Customer", component: lazy(() => import("./views/pages/customers/EditCustomer")) },
    // Customers End

    // Drivers Start
    { path: usersService.drivers.mainRoute, exact: true, name: "Drivers", component: lazy(() => import("./views/pages/drivers/Drivers")) },
    { path: usersService.drivers.addRoute, exact: true, name: "Add Driver", component: lazy(() => import("./views/pages/drivers/AddDriver")) },
    { path: `${usersService.drivers.editRoute}/:id`, exact: true, name: "Edit Driver", component: lazy(() => import("./views/pages/drivers/EditDriver")) },
    // Drivers End

    // Driver Attachment Start
    { path: `${driverAttachmentsService.mainRoute}/:driverId`, exact: true, name: "Driver Attachments", component: lazy(() => import("./views/pages/driver-attachments/DriverAttachments")) },
    { path: `${driverAttachmentsService.updateAttachment.mainRoute}/:id/:driverId`, exact: true, name: "Update Driver Attachment", component: lazy(() => import("./views/pages/driver-attachments/UpdateDriverAttachment")) },
    // Driver Attachment End

    // Drivers Tracking Start
    { path: trackDriversService.mainRoute, exact: true, name: "Track Drivers", component: lazy(() => import("./views/pages/track-drivers/TrackDrivers")) },
    // Drivers Tracking End

    // Campaigns Start
    { path: campaignsService.mainRoute, exact: true, name: "Campaigns", component: lazy(() => import("./views/pages/campaigns/Campaigns")) },
    { path: campaignsService.addRoute, exact: true, name: "Add Campaign", component: lazy(() => import("./views/pages/campaigns/AddCampaign")) },
    { path: `${campaignsService.editRoute}/:id`, exact: true, name: "Edit Campaign", component: lazy(() => import("./views/pages/campaigns/EditCampaign")) },
    // Campaigns End

    // MobileTranslation start

    { path: mobileTranslationService.mainRoute, exact: true, name: "Mobile Translation", component: lazy(() => import("./views/pages/mobileTranslation/MobileTranslation")) },
    { path: `${mobileTranslationService.editRoute}/:id`, exact: true, name: "Mobile Translation Item", component: lazy(() => import("./views/pages/mobileTranslation/MobileTranslationItem")) },

    // MobileTranslation end

    // Admins start

    { path: adminService.mainRoute, exact: true, name: "Admins", component: lazy(() => import("./views/pages/Admins/Admins")) },
    { path: adminService.addRoute, exact: true, name: "Add Admin", component: lazy(() => import("./views/pages/Admins/AddAdmin")) },
    { path: `${adminService.editRoute}/:id`, exact: true, name: "Edit Admin", component: lazy(() => import("./views/pages/Admins/EditAdmin")) },

    // Admins end

    // Notifications start

    { path: notificationService.mainRoute, exact: true, name: "Notifications", component: lazy(() => import("./views/pages/notifications/SendNotidications")) },
    //  { path: adminService.addRoute, exact: true, name: "Add Admin", component: lazy(() => import("./views/pages/Admins/AddAdmin")) },
    //  { path: `${adminService.editRoute}/:id`, exact: true, name: "Edit Admin", component: lazy(() => import("./views/pages/Admins/EditAdmin")) },

    // Notifications end
];

export default routes;
