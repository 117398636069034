import swal from "sweetalert";

export const handleResponse = ({ success, ...rest }) => ({ success, ...rest });

export const displayAlert = (title, text, icon, buttons) => swal({ title, text, icon, buttons });

export const getResponseErrors = (responseErrors) => {
    if (!responseErrors) return {};

    const errors = Object.entries(responseErrors).map(([fieldName, [fieldValue]]) => [snakeToCamelCase(fieldName), fieldValue]);

    return Object.fromEntries(errors);
};

export const prepareRequestPayload = (caseType, payload) => {
    const errors = Object.entries(payload).map(([fieldName, fieldValue]) => [caseType === "snake" ? camelToSnakeCase(fieldName) : snakeToCamelCase(fieldName), fieldValue]);

    return Object.fromEntries(errors);
};

export const snakeToCamelCase = (str) => str.replace(/([-_]\w)/g, (str) => str[1].toUpperCase());

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const splitCamelCase = (str) => str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");

export const prefixAdminEndpoint = (endpoint) => `Admin/${endpoint}`;

export const mapSelectData = (options) => (Array.isArray(options) ? options.map((option) => ({ label: option.name, value: option.id, ...option })) : []);

export const getSelectNumbersBySize = (size) => Array.from({ length: size }, (_, idx) => ({ label: idx + 1, value: idx + 1 }));

export const languageTableHeaders = (...tableHeaders) => ["languageId", "languageType", ...tableHeaders, "action"];

export const insertActionToTable = (data, thValue, removeAction) => data.map((item) => ({ ...item, [thValue]: item.value, removeAction }));

export const uploadDataFactory = ({ file = "", blob = null, prefix = "", isUploaded = false }) => ({ file, blob, prefix, isUploaded });

export const getBadge = (status) => {
    switch (status) {
        case "Active":
        case "Approved":
        case "Arrived":
        case "Completed":
        case "Open":
            return "success";
        case "Inactive":
            return "secondary";
        case "Pending":
        case "In Process":
        case "Picked up":
            return "warning";
        case "Banned":
        case "Rejected":
        case "Canceled":
        case "Closed":
            return "danger";
        default:
            return "primary";
    }
};

export const getStatus = (status) => (status === 1 ? "Active" : "Inactive");

export const getApprove = (status) => (status === 2 ? "Approved" : status === 1 ? "Pending" : "Rejected");

export const getOrderStatus = (status) => {
    switch (status) {
        case -2:
            return "Canceled";
        case 1:
            return "Submitted";
        case 2:
            return "In Process";
        case 3:
            return "Picked up";
        case 4:
            return "Arrived";
        case 5:
            return "Completed";
        default:
            return null;
    }
};
