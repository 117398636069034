import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class PopupPointsService {
    #crudService = new CrudService('/popupPoints', '/addPopupPoint', '/editPopupPoint', apiEndpoints.popupPoints);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllPopupPoints = () => this.#crudService.getAll();

    getSinglePopupPoint = (popupPointId) => this.#crudService.getSingle(popupPointId);

    addPopupPoint = (newPopupPoint) => this.#crudService.add(newPopupPoint);

    updatePopupPoint = (popupPointId, updatedPopupPoint) => this.#crudService.update(popupPointId, updatedPopupPoint);

    deletePopupPoint = (popupPointId) => this.#crudService.delete(popupPointId);
}

export const popupPointsService = new PopupPointsService();
