import { apiService } from './ApiService';
import { handleResponse } from '../utils';

// Core Service
// Service to handle CRUD operations
// @param=mainRoute; -> used to bind the route that the consumer component will be rendered on
export class CrudService {
    #baseEndpoint = '';
    #mainRoute = '';
    #addRoute = '';
    #editRoute = '';

    constructor(mainRoute, addRoute, editRoute, baseEndpoint) {
        this.#mainRoute = mainRoute;
        this.#addRoute = addRoute;
        this.#editRoute = editRoute;
        this.#baseEndpoint = baseEndpoint;
    }

    get mainRoute() {
        return this.#mainRoute;
    }

    get addRoute() {
        return this.#addRoute;
    }

    get editRoute() {
        return this.#editRoute;
    }

    set baseEndpoint(newEndpoint) {
        this.#baseEndpoint = newEndpoint;
    }

    async getAll() {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().get(this.#baseEndpoint).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }

    async getAllByQuery(query) {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().get(`${ this.#baseEndpoint }${ query }`).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getSingle(id) {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().get(`${ this.#baseEndpoint }/${ id }`).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }

    async add(data) {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().post(this.#baseEndpoint, data).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }

    async update(id, updatedData) {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().put(`${ this.#baseEndpoint }/${ id }`, updatedData).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }

    async delete(id) {
        try {
            return handleResponse({ success: true, ...(await apiService.authenticated().delete(`${ this.#baseEndpoint }/${ id }`).then(({ data }) => data)) });
        }
        catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }
}
