import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class DayService {
    #crudService = new CrudService('/days', '/addDay', '/editDay', apiEndpoints.days);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllDays = () => this.#crudService.getAll();

    getSingleDay = (dayId) => this.#crudService.getSingle(dayId);

    addDay = (newDay) => this.#crudService.add(newDay);

    updateDay = (dayId, updatedDay) => this.#crudService.update(dayId, updatedDay);

    deleteDay = (dayId) => this.#crudService.delete(dayId);
}

export const dayService = new DayService();
