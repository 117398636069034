import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class MenuCategoriesService {
    #crudService = new CrudService('/menuCategories', '/addMenuCategory', '/editMenuCategory', apiEndpoints.storeMenuCategories);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllMenuCategories = (storeId) => this.#crudService.getAllByQuery(`?store_id=${ storeId }`);

    getSingleMenuCategory = (menuCategoryId) => this.#crudService.getSingle(menuCategoryId);

    addMenuCategory = (newMenuCategory) => this.#crudService.add(newMenuCategory);

    updateMenuCategory = (menuCategoryId, updatedMenuCategory) => this.#crudService.update(menuCategoryId, updatedMenuCategory);

    deleteMenuCategory = (menuCategoryId) => this.#crudService.delete(menuCategoryId);
}

export const menuCategoriesService = new MenuCategoriesService();
