import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class PublicPagesService {
    #crudService = new CrudService('/publicPages', '/addPublicPage', '/editPublicPage', apiEndpoints.publicPages);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllPublicPages = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    getSinglePublicPage = (publicPageId) => this.#crudService.getSingle(publicPageId);

    addPublicPage = (newPublicPage) => this.#crudService.add(newPublicPage);

    updatePublicPage = (publicPageId, updatedPublicPage) => this.#crudService.update(publicPageId, updatedPublicPage);

    deletePublicPage = (publicPageId) => this.#crudService.delete(publicPageId);
}

export const publicPagesService = new PublicPagesService();
