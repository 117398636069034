import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class HomeAdsService {
    #crudService = new CrudService('/homeAds', '/addHomeAd', '/editHomeAd', apiEndpoints.homeAds);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllHomeAds = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    getSingleHomeAd = (homeAdId) => this.#crudService.getSingle(homeAdId);

    addHomeAd = (newHomeAd) => this.#crudService.add(newHomeAd);

    updateHomeAd = (homeAdId, updatedHomeAd) => this.#crudService.update(homeAdId, updatedHomeAd);

    deleteHomeAd = (homeAdId) => this.#crudService.delete(homeAdId);
}

export const homeAdsService = new HomeAdsService();
