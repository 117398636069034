import React, { useEffect } from "react";
import { TheContent, TheFooter, Header, TheSidebar } from "./index";
const adminType = localStorage.getItem("adminType");

const TheLayout = () => {
    useEffect(() => {
        if (!adminType) {
            window.location.reload();
        }
    }, []);

    return (
        <div className="c-app c-default-layout">
            <TheSidebar />

            <div className="c-wrapper">
                <Header />

                <div className="c-body">
                    <TheContent />
                </div>

                <TheFooter />
            </div>
        </div>
    );
};

export default TheLayout;
