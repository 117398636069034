import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class StoreService {
    #crudService = new CrudService("/stores", "/addStore", "/editStore", apiEndpoints.stores);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    get branches() {
        return { mainRoute: "/storeBranches", addRoute: "/addStoreBranch", editRoute: "/editStoreBranch" };
    }

    getAllStores = (countryId, parentId = 0) => this.#crudService.getAllByQuery(`?country_id=${countryId}&parent_id=${parentId}`);

    getCountryCategories = async (countryId) => {
        this.#crudService.baseEndpoint = apiEndpoints.getCountryCategories;

        const countryCategories = await this.#crudService.getAllByQuery(`?country_id=${countryId}`);

        this.#crudService.baseEndpoint = apiEndpoints.stores;

        return countryCategories;
    };

    getSingleStore = (storeId) => this.#crudService.getSingle(storeId);

    addStore = (newStore) => this.#crudService.add(newStore);

    updateStore = (storeId, updatedStore) => this.#crudService.update(storeId, updatedStore);

    deleteStore = (storeId) => this.#crudService.delete(storeId);
}

export const storesService = new StoreService();
