import { CrudService } from "./CrudService";
import { apiEndpoints } from "../api";

class OrderComplaints {
    #crudService = new CrudService("/getOrderComplaints", "", "", apiEndpoints.getOrderComplaints);
    mainRoute = this.#crudService.mainRoute;

    getOrderComplaints = (orderId) => this.#crudService.getAllByQuery(`?order_id=${orderId}`);
}

class OrderOffers {
    #crudService = new CrudService("/getOrderOffers", "", "", apiEndpoints.getOrderOffers);
    mainRoute = this.#crudService.mainRoute;

    getOrderOffers = (orderId) => this.#crudService.getAllByQuery(`?order_id=${orderId}`);
}

class OrderMessages {
    #crudService = new CrudService("/getOrderMessages", "", "", apiEndpoints.getOrderMessages);
    mainRoute = this.#crudService.mainRoute;

    getOrderMessages = (orderId) => this.#crudService.getAllByQuery(`?order_id=${orderId}`);
}

class OrderReviews {
    #crudService = new CrudService("/getOrderReviews", "", "", apiEndpoints.getOrderReviews);
    mainRoute = this.#crudService.mainRoute;

    getOrderReviews = (orderId) => this.#crudService.getAllByQuery(`?order_id=${orderId}`);
}

class OrderDriverNotifications {
    #crudService = new CrudService("/getOrderDriverNotification", "", "", apiEndpoints.getOrderDriverNotification);
    mainRoute = this.#crudService.mainRoute;

    getOrderDriverNotification = (orderId) => this.#crudService.getAllByQuery(`?order_id=${orderId}`);
}

class CancelOrder {
    #crudService = new CrudService("/orders", "", "", apiEndpoints.cancelOrderByUser);
    mainRoute = this.#crudService.mainRoute;

    cancelOrder = (order_id) => this.#crudService.add({ order_id });
}
class OrdersService {
    #crudService = new CrudService("/orders", "", "", apiEndpoints.orders);
    mainRoute = this.#crudService.mainRoute;
    orderComplaints = new OrderComplaints();
    orderOffers = new OrderOffers();
    orderMessages = new OrderMessages();
    orderReviews = new OrderReviews();
    orderDriverNotifications = new OrderDriverNotifications();
    cancelOrder = new CancelOrder();

    getAllOrders = (countryId, page, key) => this.#crudService.getAllByQuery(`?country_id=${countryId}&page=${page}`);
    getSearchOrders = (countryId, page, key) => this.#crudService.getAllByQuery(`?country_id=${countryId}&page=${page}&key=${key}`);

    getSingleOrder = (orderId) => this.#crudService.getSingle(orderId);
}

export const ordersService = new OrdersService();
