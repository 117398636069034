import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class AdminService {
    #crudService = new CrudService("/Admins", "/addAdmin", "/editAdmin", apiEndpoints.users);

    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllUsers = (countryId, type, page, storeId = 0) => this.#crudService.getAllByQuery(`?country_id=${countryId}&type=${98}&page=${page}&store_id=${storeId}`);

    getSingleUser = (userId) => this.#crudService.getSingle(userId);

    addUser = (newUser) => this.#crudService.add(newUser);

    updateUser = (userId, updatedUser) => this.#crudService.update(userId, updatedUser);

    deleteUser = (userId) => this.#crudService.delete(userId);
}

export const adminService = new AdminService();
