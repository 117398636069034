import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class CountryCategoryService {
    #crudService = new CrudService('/countryCategories', '/addCountryCategory', '/editCountryCategory', apiEndpoints.countryCategories);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;

    getAllCountryCategories = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    addCountryCategory = (newCountryCategory) => this.#crudService.add(newCountryCategory);

    deleteCountryCategory = (countryCategoryId) => this.#crudService.delete(countryCategoryId);
}

export const countryCategoryService = new CountryCategoryService();
