import { CrudService } from "./CrudService";
import { apiEndpoints } from "../api";

class DriversPaymentsService {
    #crudService = new CrudService("/driversAccountStatement", "/addDriverPayment", "", apiEndpoints.driverPayments);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    driverPaymentsRoute = "/driverPayments";
    driverTransactionsRoute = "/driverTransactions";

    getAllDriversPayments = (countryId, page) => this.#crudService.getAllByQuery(`?country_id=${countryId}&page=${page}`);

    getSingleDriverPayment = (driverPaymentId) => this.#crudService.getSingle(driverPaymentId);

    addDriverPayment = (newDriverPayment) => this.#crudService.add(newDriverPayment);

    deleteDriverPayment = (driverPaymentId) => this.#crudService.delete(driverPaymentId);
}

export const driversPaymentsService = new DriversPaymentsService();
