import { handleResponse } from "../utils";
import { apiService } from "./ApiService";
import { apiEndpoints } from "../api";

class GeneralService {
    async uploadFile(file) {
        try {
            return handleResponse({
                success: true,
                ...(await apiService
                    .authenticated()
                    .post(apiEndpoints.uploadFile, file)
                    .then(({ data }) => data)),
            });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }

    async getAdminProfile() {
        try {
            return handleResponse({
                success: true,
                adminProfile: await apiService
                    .authenticated()
                    .get(apiEndpoints.getAdminProfile)
                    .then(({ data }) => data.data),
            });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }
    async ExportExcel(country_id, from_id, to_id) {
        try {
            return handleResponse({
                success: true,
                data: await apiService
                    .authenticated()
                    .get(`${apiEndpoints.exportOrdersExcel}?country_id=${country_id}&from_id=${from_id}&to_id=${to_id}`)
                    .then(({ data }) => data.data),
            });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response.data });
        }
    }
}

export const generalServices = new GeneralService();
