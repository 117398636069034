import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class CampaignsService {
    #crudService = new CrudService('/campaigns', '/addCampaign', '/editCampaign', apiEndpoints.campaigns);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllCampaigns = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    getSingleCampaign = (campaignId) => this.#crudService.getSingle(campaignId);

    addCampaign = (newCampaign) => this.#crudService.add(newCampaign);

    updateCampaign = (campaignId, updatedCampaign) => this.#crudService.update(campaignId, updatedCampaign);

    deleteCampaign = (campaignId) => this.#crudService.delete(campaignId);
}

export const campaignsService = new CampaignsService();
