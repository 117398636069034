import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class LogoutDriver {
    #crudService = new CrudService("/drivers", "", "", apiEndpoints.markAsLogout);
    mainRoute = this.#crudService.mainRoute;

    logoutDriver = (user_id) => this.#crudService.add({ user_id });
}

class UsersService {
    #crudService = new CrudService("/users", "/addUser", "/editUser", apiEndpoints.users);

    get customers() {
        return { mainRoute: "/customers", addRoute: "/addCustomer", editRoute: "/editCustomer" };
    }

    get drivers() {
        return { mainRoute: "/drivers", addRoute: "/addDriver", editRoute: "/editDriver" };
    }

    get store() {
        return { mainRoute: "/storeUser", addRoute: "/addStoreUser", editRoute: "/editStoreUser" };
    }
    logoutDriver = new LogoutDriver();

    // countryId: customers -> filter, drivers -> filter, store user -> 0
    // type: 0 -> customers , 1 -> drivers, 2 -> store user
    // Page: all pagination
    // storeId: 0 -> customers , 0 -> drivers, (from link) -> store user
    getAllUsers = (countryId, type, page, storeId = 0, key = "") => this.#crudService.getAllByQuery(`?country_id=${countryId}&type=${type}&page=${page}&store_id=${storeId}&key=${key}`);

    getSingleUser = (userId) => this.#crudService.getSingle(userId);

    addUser = (newUser) => this.#crudService.add(newUser);

    updateUser = (userId, updatedUser) => this.#crudService.update(userId, updatedUser);

    deleteUser = (userId) => this.#crudService.delete(userId);
}

export const usersService = new UsersService();
