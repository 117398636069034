import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class MenuItemAddonsGroupsService {
    #crudService = new CrudService('/menuItemAddonsGroups', '/addMenuItemAddonGroup', '/editMenuItemAddonGroup', apiEndpoints.storeMenuItemAddonsGroups);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllMenuItemAddonsGroups = (storeMenuItemId) => this.#crudService.getAllByQuery(`?store_menu_item_id=${ storeMenuItemId }`);

    getSingleMenuItemAddonGroup = (menuItemAddonGroupId) => this.#crudService.getSingle(menuItemAddonGroupId);

    addMenuItemAddonGroup = (newMenuItemAddonGroup) => this.#crudService.add(newMenuItemAddonGroup);

    updateMenuItemAddonGroup = (menuItemAddonGroupId, updatedMenuItemAddonGroup) => this.#crudService.update(menuItemAddonGroupId, updatedMenuItemAddonGroup);

    deleteMenuItemAddonGroup = (menuItemAddonGroupId) => this.#crudService.delete(menuItemAddonGroupId);
}

export const menuItemAddonsGroupsService = new MenuItemAddonsGroupsService();
