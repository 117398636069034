import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class AreaService {
    #crudService = new CrudService('/areas', '/addArea', '/editArea', apiEndpoints.areas);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllAreas = (cityId) => this.#crudService.getAllByQuery(`?city_id=${ cityId }`);

    getSingleArea = (areaId) => this.#crudService.getSingle(areaId);

    addArea = (newArea) => this.#crudService.add(newArea);

    updateArea = (areaId, updateArea) => this.#crudService.update(areaId, updateArea);

    deleteArea = (areaId) => this.#crudService.delete(areaId);
}

export const areaService = new AreaService();
