import { displayAlert, prefixAdminEndpoint } from "../utils";
import { apiService } from "../services/ApiService";

export const apiEndpoints = {
    uploadFile: "uploadFile",
    getAllMobileTranslationItem: prefixAdminEndpoint("MobileTranslations"),
    SubmitMobileTranslations: prefixAdminEndpoint("SubmitMobileTranslations"),
    authentication: { adminLogin: prefixAdminEndpoint("adminLogin") },

    getAdminProfile: prefixAdminEndpoint("getAdminProfile"),
    exportOrdersExcel: "exportOrdersExcel",

    countries: prefixAdminEndpoint("countries"),
    cancelOrderByUser: "cancelTheOrder",
    // languages: "getAllLanguages",
    languages: prefixAdminEndpoint("languages"),

    cities: prefixAdminEndpoint("cities"),

    categories: prefixAdminEndpoint("categories"),

    areas: prefixAdminEndpoint("areas"),

    countryCategories: prefixAdminEndpoint("country_categories"),

    appIntro: prefixAdminEndpoint("app_intro"),

    days: prefixAdminEndpoint("days"),

    driverRequirements: prefixAdminEndpoint("driver_requirements"),

    homeAds: prefixAdminEndpoint("home_x"),

    orders: prefixAdminEndpoint("orders"),

    getOrderComplaints: prefixAdminEndpoint("getOrderComplaints"),

    getOrderOffers: prefixAdminEndpoint("getOrderOffers"),

    getOrderMessages: prefixAdminEndpoint("getOrderMessages"),

    getOrderReviews: prefixAdminEndpoint("getOrderReviews"),
    getOrderDriverNotification: prefixAdminEndpoint("getOrderDriverNotification"),

    popupPoints: prefixAdminEndpoint("popup_points"),

    publicPages: prefixAdminEndpoint("public_pages"),

    stores: prefixAdminEndpoint("stores"),

    getCountryCategories: prefixAdminEndpoint("getCountryCategories"),

    storeMenuCategories: prefixAdminEndpoint("storeMenuCategories"),

    storeMenuItems: prefixAdminEndpoint("storeMenuItems"),

    getStoreAllProductsOnly: prefixAdminEndpoint("getStoreAllProductsOnly"),

    storeMenuItemAddonsGroups: prefixAdminEndpoint("storeMenuItemsAddonsGroups"),

    storeImages: prefixAdminEndpoint("storeImages"),

    storeCampaigns: prefixAdminEndpoint("storeCampaigns"),

    storeWorkingHours: prefixAdminEndpoint("storeWorkingHours"),

    users: prefixAdminEndpoint("users"),

    changeStoreUserPassword: prefixAdminEndpoint("changeStoreUserPassword"),

    getDriverAttachments: prefixAdminEndpoint("getDriverAttachments"),

    updateDriverAttachments: prefixAdminEndpoint("updateDriverAttachments"),

    campaigns: prefixAdminEndpoint("campaigns"),

    getDriverForTracking: prefixAdminEndpoint("getDriverForTracking"),

    driverPayments: prefixAdminEndpoint("DriverPayments"),

    getNotAddedCountryCategories: prefixAdminEndpoint("getNotAddedCountryCategories"),
    storeCloseOpen: prefixAdminEndpoint("getStoreWorkingLog"),
    markAsLogout: prefixAdminEndpoint("markAsLogout "),
    sendNotifications: prefixAdminEndpoint("sendNotifications"),
};

export const imageLink = process.env.REACT_APP_API_IMAGE_LINK;

export const httpErrors = async (error) => {
    const expectedError = error.response?.status >= 400 && error.response?.status < 500;

    if (error.response?.status === 401 && error.response.config?.headers.Authorization.length > 10 && error.response?.data.message.includes("Unauthenticated."))
        await displayAlert("Error", "Your session has ended", "error", "Login").then(() => {
            apiService.removeAccessToken();
            window.location.replace("/");
        });

    // if (!expectedError) window.location.reload();
    // if (!expectedError)      await  displayAlert("Error", "Unexpected error occurred", "error", "Reload").then(() => window.location.reload());

    return Promise.reject(error);
};
