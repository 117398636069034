import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class StoreImagesService {
    #crudService = new CrudService('/storeImages', '/addStoreImage', '', apiEndpoints.storeImages);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;

    getAllStoreImages = (storeId) => this.#crudService.getAllByQuery(`?store_id=${ storeId }`);

    getSingleStoreImage = (storeImageId) => this.#crudService.getSingle(storeImageId);

    addStoreImage = (newStoreImage) => this.#crudService.add(newStoreImage);

    updateStoreImage = (storeImageId, updatedStoreImage) => this.#crudService.update(storeImageId, updatedStoreImage);

    deleteStoreImage = (storeImageId) => this.#crudService.delete(storeImageId);
}

export const storeImagesService = new StoreImagesService();
