import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class UpdateAttachment {
    #crudService = new CrudService('/updateDriverAttachments', '', '', apiEndpoints.updateDriverAttachments);
    mainRoute = this.#crudService.mainRoute;
    editRoute = this.#crudService.editRoute;

    updateDriverAttachment = (newAttachment) => this.#crudService.add(newAttachment);
}

class DriverAttachmentsService {
    #crudService = new CrudService('/driverAttachments', '/updateDriverAttachments', '', apiEndpoints.getDriverAttachments);
    mainRoute = this.#crudService.mainRoute;
    updateAttachment = new UpdateAttachment();

    getAllDriverAttachments = (userId) => this.#crudService.getAllByQuery(`?user_id=${ userId }`);
}

export const driverAttachmentsService = new DriverAttachmentsService();
