import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class StoreUserService {
    #crudService = new CrudService('/changeStoreUserPassword', '', '', apiEndpoints.changeStoreUserPassword);
    mainRoute = this.#crudService.mainRoute;

    changePassword = (userData) => this.#crudService.add(userData);
}

export const storeUserService = new StoreUserService();
