import { CrudService } from './CrudService';
import { apiEndpoints } from '../api';

class DriverRequirementService {
    #crudService = new CrudService('/driverRequirements', '/addDriverRequirement', '/editDriverRequirement', apiEndpoints.driverRequirements);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllDriverRequirements = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    getSingleDriverRequirement = (driverRequirementId) => this.#crudService.getSingle(driverRequirementId);

    addDriverRequirement = (newDriverRequirement) => this.#crudService.add(newDriverRequirement);

    updateDriverRequirement = (driverRequirementId, updatedDriverRequirement) => this.#crudService.update(driverRequirementId, updatedDriverRequirement);

    deleteDriverRequirement = (driverRequirementId) => this.#crudService.delete(driverRequirementId);
}

export const driverRequirementService = new DriverRequirementService();
