import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class CountryService {
    #crudService = new CrudService('/countries', '/addCountry', '/editCountry', apiEndpoints.countries);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllCountries = () => this.#crudService.getAll();

    getSingleCountry = (countryId) => this.#crudService.getSingle(countryId);

    addCountry = (newCountry) => this.#crudService.add(newCountry);

    updateCountry = (countryId, updatedCountry) => this.#crudService.update(countryId, updatedCountry);

    deleteCountry = (countryId) => this.#crudService.delete(countryId);
}

export const countryService = new CountryService();
