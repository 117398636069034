import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class StoreCampaignsService {
    #crudService = new CrudService('/storeCampaigns', '/addStoreCampaign', '/editStoreCampaign', apiEndpoints.storeCampaigns);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllStoreCampaigns = (storeId) => this.#crudService.getAllByQuery(`?store_id=${ storeId }`);

    getSingleStoreCampaign = (storeCampaignId) => this.#crudService.getSingle(storeCampaignId);

    addStoreCampaign = (newStoreCampaign) => this.#crudService.add(newStoreCampaign);

    updateStoreCampaign = (storeCampaignId, updatedStoreCampaign) => this.#crudService.update(storeCampaignId, updatedStoreCampaign);

    deleteStoreCampaign = (storeCampaignId) => this.#crudService.delete(storeCampaignId);
}

export const storeCampaignsService = new StoreCampaignsService();
