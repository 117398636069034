import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class MenuItemsService {
    #crudService = new CrudService("/menuItems", "/addMenuItem", "/editMenuItem", apiEndpoints.storeMenuItems);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllMenuItems = (storeMenuCategoryId) => this.#crudService.getAllByQuery(`?store_menu_category_id=${storeMenuCategoryId}`);

    getAllProducts = async (storeId) => {
        this.#crudService.baseEndpoint = apiEndpoints.getStoreAllProductsOnly;

        const products = await this.#crudService.getAllByQuery(`?store_id=${storeId}`);

        this.#crudService.baseEndpoint = apiEndpoints.storeMenuItems;

        return products;
    };

    getSingleMenuItem = (menuItemId) => this.#crudService.getSingle(menuItemId);

    addMenuItem = (newMenuItem) => this.#crudService.add(newMenuItem);

    updateMenuItem = (menuItemId, updatedMenuItem) => this.#crudService.update(menuItemId, updatedMenuItem);

    deleteMenuItem = (menuItemId) => this.#crudService.delete(menuItemId);
}

export const menuItemsService = new MenuItemsService();
