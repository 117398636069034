import { apiEndpoints } from "../api";
import { CrudService } from "./CrudService";

class SubmitMobileTranslations {
    #crudService = new CrudService("", "", "", apiEndpoints.SubmitMobileTranslations);

    EditItemTranslation = (contents) => this.#crudService.add(contents);
}

class MobileTranslationService {
    #crudService = new CrudService("/MobileTranslation", "", "/MobileTranslationItems", apiEndpoints.getAllMobileTranslationItem);
    mainRoute = this.#crudService.mainRoute;
    editRoute = this.#crudService.editRoute;

    submitMobileTranslations = new SubmitMobileTranslations();

    getAllMobileTranslationItem = (typeId) => this.#crudService.getAllByQuery(`?type=${typeId}`);

    getSingleMenuItem = (menuItemId) => this.#crudService.getSingle(menuItemId);

    addMenuItem = (newMenuItem) => this.#crudService.add(newMenuItem);

    deleteMenuItem = (menuItemId) => this.#crudService.delete(menuItemId);
}

export const mobileTranslationService = new MobileTranslationService();
