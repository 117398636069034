class RolesTypes {
    roles = { user: 1, driver: 2, store: 3 };

    roleTypesOptions = [{ label: 'User', value: this.roles.user }, { label: 'Driver', value: this.roles.driver }, { label: 'Store', value: this.roles.store }];

    defaultOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

    getDefaultOptions = (value) => !!value ? this.defaultOptions[0] : this.defaultOptions[1];

    driverTypes = { scooter: 1, mini: 2, mega: 3 };

    driverTypeOptions = [{ label: 'Scooter', value: this.driverTypes.scooter }, { label: 'Mini', value: this.driverTypes.mini }, { label: 'Mega', value: this.driverTypes.mega }];

    getDriverType = (type) => type === 1 ? this.driverTypeOptions[0].label : type === 2 ? this.driverTypeOptions[1].label : this.driverTypeOptions[2].label;

    gender = { female: 0, male: 1 };

    genderOptions = [{ label: 'Female', value: this.gender.female }, { label: 'Male', value: this.gender.male }];

    userTypes = { customer: 0, driver: this.driverTypes, store: 4 };

    statusOptions = [{ label: 'Active', value: 1 }, { label: 'inactive', value: 0 }];

    machineNameOptions = [
        { label: 'User Order Complaint', value: 'user_order_complaint' },
        { label: 'User Cancel Order', value: 'user_order_cancel' },
        { label: 'Driver Order Complaint', value: 'driver_order_complaint' },
        { label: 'Driver Cancel Order', value: 'driver_order_cancel' },
    ];

    paymentTypes = { debit: 1, credit: 2 };

    paymentTypesOptions = [{ label: 'Debit', value: this.paymentTypes.debit }, { label: 'Credit', value: this.paymentTypes.credit }];

    getPaymentType = (type) => type === 1 ? this.paymentTypesOptions[0].label : this.paymentTypesOptions[1].label;

    getStatusSelectedOption = (status) => status === 1 ? this.statusOptions[0] : this.statusOptions[1];
}

export const rolesTypes = new RolesTypes();
