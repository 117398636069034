import { apiEndpoints } from '../api';
import { CrudService } from './CrudService';

class CityService {
    #crudService = new CrudService('/cities', '/addCity', '/editCity', apiEndpoints.cities);
    mainRoute = this.#crudService.mainRoute;
    addRoute = this.#crudService.addRoute;
    editRoute = this.#crudService.editRoute;

    getAllCities = (countryId) => this.#crudService.getAllByQuery(`?country_id=${ countryId }`);

    getSingleCity = (cityId) => this.#crudService.getSingle(cityId);

    addCity = (newCity) => this.#crudService.add(newCity);

    updateCity = (cityId, updatedCity) => this.#crudService.update(cityId, updatedCity);

    deleteCity = (cityId) => this.#crudService.delete(cityId);
}

export const cityService = new CityService();
